@use "../../styles//buttonStyles.scss" as *;
@use "../../index.module.scss" as *;

.login-wrapper {
  display: grid;
  place-items: center;
  grid-area: light;
  grid-column: span 2;
  height: calc(100vh - 5em);
  padding-top: 5em;
  overflow: auto;
}

@media screen and (max-width: 1060px) {
  .login-wrapper {
    grid-column: span 1;
  }
}

.register-container {
  display: grid;
  width: 100%;
  .register-main-section {
    display: grid;
    align-content: start;
    justify-items: center;
    width: 100%;
    // background-color: var(--mainNavColorSeeTrough);
    padding: 0 10px;
    box-sizing: border-box;
    .user-role-selection {
      display: grid;
      justify-items: center;
      grid-auto-flow: column;
      margin-bottom: 20px;
    }
    p.form-validator-prompt {
      font: 600 0.8rem var(--mainFontFamily);
      margin: 0;
      color: #ff4154;
    }
    .register-button-update {
      @include button;
      margin: 20px 0;
      padding: 15px 0px;
    }
  }
  @media #{$laptop} {
    align-content: center;
    // .register-main-section {
    //   background-color: var(--mainNavColor);
    // }
  }
}
