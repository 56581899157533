@use "../../../../styles//labelStyles.scss" as *;
@use "../../../../styles//buttonStyles.scss" as *;

.pick-a-car-modal-container {
  z-index: 201;
  position: absolute;
  display: none;
  height: 100%;
  width: 100vw;
  background-color: rgba(234, 234, 234, 0.6);
  place-content: center;
  &.show {
    display: grid;
  }
  .pick-a-car-modal-close-container {
    display: grid;
    place-content: end;
    cursor: pointer;
  }
  .pick-a-car-modal-car-list {
    height: 440px;
    display: grid;
    overflow: auto;
    grid-gap: 3px;
    background-color: var(--primary);
    align-content: start;
    border-radius: 5px;
    width: 100vw;
    max-width: 400px;
    position: relative;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: darkgrey;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--primaryHoverColor);
      &:hover {
        background: goldenrod;
      }
    }
    .modal-car-item {
      margin: 5px;
      height: 60px;
      display: grid;
      background-color: var(--primaryHoverColor);
      padding: 5px;
      box-sizing: border-box;
      border-radius: 4px;
      justify-items: center;
      grid-template-columns: 1fr 4fr;
      color: white;
      align-items: center;
      &.picked {
        background-color: teal;
        color: var(--primaryHoverColor);
      }
      &.hide {
        display: none;
      }

      .modal-car-item-radio {
        position: absolute;
        width: 100%;
        height: 60px;
        opacity: 0;
        margin: 0;
        cursor: pointer;
        z-index: 205;
      }
      div {
        grid-column: 2;
        .modal-car-brand,
        .modal-car-model {
          @include label;
          width: min-content;
        }
      }
      .modal-car-registration {
        @include label;
        width: min-content;
        grid-column: 2;
        color: darkgrey;
      }
      svg {
        height: 20px;
        g path {
          fill: white;
        }
      }
      &:hover {
        background-color: goldenrod;
        .modal-car-brand,
        div .modal-car-model {
          color: var(--primaryHoverColor);
        }
        .modal-car-registration {
          color: var(--primary);
        }
      }
    }
    .pick-a-car-model-no-cars {
      @include label;
      max-width: 400px;
    }
    .service-confirmation-prompt {
      position: absolute;
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 400px;
      width: 100%;
      height: 400px;
      place-content: center;
      &.hide {
        display: none;
      }
      span {
        grid-column: 1/3;
        text-align: center;
        @include label;
        width: 100%;
        &:last-of-type {
          margin-bottom: 20px;
        }
      }
    }
  }
  button {
    @include button;
  }
}
