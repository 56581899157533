/* @import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"); */
:root {
  --primary: rgb(143, 53, 68);
  --secondary: rgb(255, 255, 255);
}
html {
  background-color: black;
}

body:has(#rootLP) {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1920px;
  background-color: white;
}
html:has(#rootLP),
#root:has(#rootLP) {
  overflow-y: auto;
}
p,
a,
span {
  line-height: 1.7;
}
#rootLP {
  display: grid;
  grid-column-gap: 2em;
  /* grid-template-rows: auto 1fr; */
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "light image"
    "footer footer";
  max-width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  overflow-y: auto;
}
button:hover {
  cursor: pointer;
}
nav {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em 5rem;
}
.nav_app_title {
  display: inline-flex;
  /* cursor: pointer; */
  text-decoration: none;
  background: white;
  align-items: center;
}
.nav_app_title > span {
  display: grid;
  font: 1.6em Russo One;
  color: black;
}
#rootLP:has(.login-wrapper) .bg-transparent {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}
.nav_app_title > span:nth-of-type(1) {
  color: var(--primary);
}
.nav_actions_container {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1em;
  margin-left: auto;
}
.nav_actions_container > .nav_action {
  padding: 0.75em 1.5em;
  border-radius: 40px;
  text-decoration: none;
  color: var(--primary);
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.bg-transparent {
  background-color: transparent !important;
  color: var(--secondary) !important;
}
.circle {
  aspect-ratio: 1 / 1;
  border-radius: 10% 0 0 10% / 32% 0 0 32%;
}
.text-white {
  color: white !important;
}
.bg-circle {
  grid-area: image;
  position: relative;
  z-index: -1;
  right: 0;
  background-color: var(--primary);
  background-image: url("car-bg.jfif");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}
.bg-circle::before {
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(143, 53, 68, 0.6);
  z-index: 0;
}
.bg-circle > img {
  width: 90%;
  height: auto;
  z-index: 1;
}
.light {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  grid-area: light;
  padding: 20% 0 20% 5em;
}
.light-frame {
  display: grid;
  max-width: 560px;
  grid-gap: 2rem;
}
.light-frame p {
  margin: 0;
}
.light-frame > * {
  height: min-content;
}
.titles {
  display: grid;
  grid-gap: 0.5rem;
}
.titles > * {
  margin: 0;
}
.main_btn-register {
  background-color: var(--primary);
  color: var(--secondary);
  border: 1px solid var(--primary);
  font-size: 18px;
  padding: 0.75em 1.5em;
  border-radius: 50px;
}
.main_btns {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1em;
  width: min-content;
  text-wrap: nowrap;
  height: min-content;
}
button.ghost {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 50px;
}
.small-text-boxes {
  display: grid;
  grid-gap: 1em;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 15%;
}
/* .small-text-boxes h4 {
  text-wrap: nowrap;
} */
footer {
  display: flex;
  background-color: rgb(36, 36, 36);
  padding: 3rem 5rem;
  grid-area: footer;
}
#socials {
  margin-top: 0.5em;
  display: grid;
  grid-gap: 1em;
  grid-auto-flow: column;
  width: min-content;
}
#socials > a {
  background-color: rgb(60, 60, 60);
  color: slategrey;
  display: grid;
  width: min-content;
  place-items: center;
  border-radius: 50%;
  padding: 0.5em;
  transition: all 0.1s;
}
#socials > a:hover {
  background-color: rgb(80, 80, 80);
  color: white;
}
.footer-links {
  margin-left: auto;
}
.footer-links > * {
  display: block;
  color: white;
  text-decoration: none;
}
footer .nav_app_title {
  background-color: transparent;
}
.light > .bg-circle {
  display: none;
}
.light:has(.about) {
  grid-column: span 2;
  padding: 5em 10%;
  justify-content: center;
}
@media screen and (max-width: 1570px) {
  .small-text-boxes {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
  .small-text-boxes > div {
    width: 100%;
  }
}

@media screen and (max-width: 1060px) {
  #rootLP {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      "light"
      "image"
      "footer";
  }
  .light {
    /* padding: 10% 5em 20% 5em; */
    padding: 0;
    padding-top: 10%;
    gap: 2em;
  }
  .light > .bg-circle {
    display: grid;
    height: 350px;
    border-radius: 0;
    place-items: unset;
    justify-content: center;
  }
  .bg-circle > img {
    height: 60%;
    width: auto;
  }
  .light-frame {
    max-width: 100%;
    padding: 0 5em;
  }
  .bg-circle {
    /* min-width: 200px;*/
    display: none;
  }
  .small-text-boxes {
    padding: 0 5em;
    margin: 0;
    margin-bottom: 3em;
  }
  .bg-transparent {
    color: var(--primary) !important;
    border-color: var(--primary) !important;
  }
  .nav_action:nth-child(2) {
    display: none;
  }
  .light:has(.about) {
    grid-column: span 1;
    padding: 20% 10%;
  }
}
@media screen and (max-width: 700px) {
  nav {
    padding: 1em 1em;
  }
  .light {
    padding-top: 6em;
  }
  .light-frame {
    padding: 0 1em;
  }
  .small-text-boxes {
    padding: 0 1em;
  }
  footer {
    padding: 3em 1em;
  }
}
@media screen and (max-width: 600px) {
  h1.nav_app_title {
    font-size: 1.6em;
  }
}
@media screen and (max-width: 500px) {
  footer {
    flex-direction: column;
    gap: 2em;
  }
  .footer-links {
    margin-left: 0;
  }
  h1.nav_app_title {
    font-size: 1.3em;
  }
}
