@use "../../../../index.module.scss" as *;

.service-history-list-container {
  .service-history-list-container-photo-modal {
    &.show {
      display: grid;
    }
    position: absolute;
    height: 65vh;
    background-color: lightgrey;
    border-radius: 5px;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    place-items: center;
    width: 100vw;
    max-width: 1000px;
    grid-template-rows: 25px 1fr 80px;
    @media #{$laptop} {
      grid-template-rows: 25px 1fr 114px;
      min-height: 500px;
    }
    .service-history-list-container-photo-modal-close {
      padding: 10px;
      position: absolute;
      right: 0;
      top: -10px;
      width: 32px;
      height: 32px;
    }
    .service-history-list-container-photo-modal-close:before,
    .service-history-list-container-photo-modal-close:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 33px;
      width: 2px;
      background-color: orange;
    }
    .service-history-list-container-photo-modal-close:before {
      transform: rotate(45deg);
    }
    .service-history-list-container-photo-modal-close:after {
      transform: rotate(-45deg);
    }
    .photo-modal-main-container {
      display: grid;
      width: 100%;
      height: 100%;
      grid-template-rows: 1fr 60px;

      .photo-modal-main-image-container {
        display: grid;
        width: 100%;
        height: 100%;
        max-height: 300px;
        padding: 5px 20px 0 20px;
        place-items: center;
        position: relative;
        .dummmy-placeholder {
          width: 100%;
          height: 100%;
          max-height: 300px;
          border: 2px dashed rgba(128, 128, 128, 0.4);
          display: grid;
          &.hide {
            display: none;
          }
          svg {
            width: 50%;
            height: 50%;
            place-self: center;
            opacity: 0.3;
          }
        }
        .photo-modal-main-image {
          display: none;
          &.previewed {
            display: block;
            object-fit: contain;
            position: absolute;
            max-height: 100%;
            max-width: 100%;
            z-index: 1;
            top: 0;
          }
        }
      }
      .photo-modal-buttons-section {
        display: grid;
        place-items: center;
        grid-auto-flow: column;
        button {
          display: grid;
          grid-auto-flow: column;
          border: none;
          outline: none;
          align-items: center;
          padding: 10px;
          position: relative;
          @media #{$laptop} {
            &:hover {
              background-color: lightgrey;
              border: 1px solid grey;
            }
          }
          input {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
          }
          span {
            font: 300 0.8rem Titillium Web;
            color: grey;
          }
          svg {
            height: 25px;
            width: 30px;
            path {
              fill: grey;
            }
          }
        }
        .photo-modal-main-image-delete {
          background-color: lightcoral;
          &:hover {
            border: 1px solid crimson;
            span {
              color: red;
            }
          }
        }
      }
    }
    .photo-modal-image-roll {
      display: grid;
      grid-gap: 3px;
      width: min-content;
      max-width: 100%;
      justify-self: center;
      grid-auto-flow: column;
      grid-auto-columns: min-content;
      overflow-x: auto;
      grid-row: 3;
      justify-items: center;
      position: absolute;
    }
    .photo-modal-image-box {
      height: 80px;
      width: 80px;
      position: relative;
      @media #{$laptop} {
        height: 100px;
        width: 100px;
      }
      &.empty {
        border: dashed 2px darkgrey;
      }
      .photo-modal-thumbnail {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        z-index: 0;
        top: 0;
      }
    }
  }
}
