@use "../../index.module.scss" as *;
@use "../../styles/buttonStyles.scss" as *;

.home_container {
  display: grid;
  font-family: var(--mainFontFamily);
  div.opis_logowanie {
    display: grid;
    color: var(--mainFontColor);
    z-index: 1;
    font-size: 1.5rem;
    text-align: center;
    span.opis_logowanie {
      place-self: center;
      width: calc(100vw / 13 * 12);
      padding: 20px;
      font-family: var(--selectedFontFamily);
      font-size: calc(100vw / 13);
      text-align: left;
      box-sizing: border-box;
      @media #{$mobileL} {
        font-size: 2.8rem;
        width: 520px;
      }
      span {
        color: var(--primary);
      }
    }
  }
  .panel_logowanie {
    display: grid;
    z-index: 1;
    align-items: center;
    span.opis-logowanie {
      font-size: 1.5rem;
    }
  }
  .button_home_role {
    @include button;
    display: grid;
    place-content: center;
    grid-auto-flow: column;
    width: 60%;
    margin: 15px 0px;
    &:hover {
      span {
        color: var(--hoverFontColor);
      }
    }
    svg {
      width: 40px;
      height: 40px;
      align-self: center;
      g path {
        fill: black;
      }
    }
    span {
      margin: auto 0px;
      margin-right: 20px;
      text-transform: uppercase;
      font: 400 1.2rem Russo One;
    }
  }
  .klient {
    display: grid;
    align-content: start;
    justify-items: center;
  }
  .register_prompt {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    p {
      color: var(--mainFontColor);
      font-size: 0.8rem;
    }
    p:nth-of-type(2) {
      cursor: pointer;
      &:hover {
        color: var(--hoverFontColor);
      }
    }
  }
  @media #{$laptop} {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    .panel_logowanie,
    div.opis_logowanie {
      grid-column: 2;
      .loginButton {
        width: 60%;
      }
      span.opis_logowanie {
        text-align: left;
        width: 590px;
        font: 300 3.3rem var(--selectedFontFamily);
      }
    }
    .klient .register_prompt p {
      font-size: 1rem;
    }
    .button_home_role {
      span {
        margin-right: 40px;
      }
    }
  }
}
