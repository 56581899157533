@use "../../index.module.scss" as *;

.tab_container {
  display: grid;
  visibility: hidden;
  height: 0px;
  opacity: 0;
  padding-bottom: 20px;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.2);
  &.show {
    visibility: visible;
    height: 180px;
    opacity: 1;
  }
  width: 100%;
  background-color: var(--mainNavColor);
  position: absolute;
  transition: height 0.3s ease-out, visibility 0.2s ease-out,
    opacity 0.15s ease-in;
  a {
    text-decoration: none;
    color: #232b2b;
    margin-left: 5px;
    text-transform: uppercase;
    font: 300 1.2rem Titillium Web;
    cursor: pointer;
    opacity: 0;
    justify-self: center;
    transition: opacity 0.2s 0.3s;
    visibility: hidden;
    &.show {
      visibility: visible;
      opacity: 1;
      position: relative;
      top: -12px;
    }
    // transition: opacity 0.1s ease-in-out;
    &:hover {
      color: var(--hoverFontColor);
      text-decoration: underline;
    }
  }
  .nav_menu_close_icon {
    display: none;
    &.show {
      display: block;
    }
    cursor: pointer;
    justify-self: end;
    margin: 10px 20px 0 0;
    font-size: 2rem;
    color: grey;
  }
  @media #{$laptop} {
    display: flex;
    height: auto;
    grid-row: 2;
    grid-column: 1/3;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: unset;
    opacity: 1;
    .nav_menu_close_icon {
      display: none;
    }
    a {
      visibility: visible;
      opacity: 1;
      font-size: 1.5rem;
    }
  }
}
