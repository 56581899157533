@use "../../styles//modalContainer.scss" as *;
@use "../../styles/labelStyles.scss" as *;

.success-modal-container {
  display: none;
  &.showCheckmark {
    @include container;
  }
  .svg-box {
    display: inline-block;
    position: relative;
    width: 150px;
    .success-green-stroke {
      stroke: #7cb342;
    }
    .circular {
      width: 150px;
    }
    .circular circle.path {
      stroke-dasharray: 330;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      opacity: 0.8;
      animation: 0.7s draw-circle ease-out;
    }

    /*------- Checkmark ---------*/
    .checkmark {
      stroke-width: 6.25;
      stroke-linecap: round;
      position: absolute;
      top: 56px;
      left: 49px;
      width: 52px;
      height: 40px;
    }

    .checkmark path {
      animation: 1s draw-check ease-out;
    }
  }
  .succes-modal-message {
    @include label;
    color: rgb(43, 49, 59);
  }

  @keyframes draw-circle {
    0% {
      stroke-dasharray: 0, 330;
      stroke-dashoffset: 0;
      opacity: 1;
    }

    80% {
      stroke-dasharray: 330, 330;
      stroke-dashoffset: 0;
      opacity: 1;
    }

    100% {
      opacity: 0.4;
    }
  }

  @keyframes draw-check {
    0% {
      stroke-dasharray: 49, 80;
      stroke-dashoffset: 48;
      opacity: 0;
    }

    50% {
      stroke-dasharray: 49, 80;
      stroke-dashoffset: 48;
      opacity: 1;
    }

    100% {
      stroke-dasharray: 130, 80;
      stroke-dashoffset: 48;
    }
  }
}
