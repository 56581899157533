@mixin label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100vw;
  text-align: center;
  padding: 5px;
  position: relative;
  box-sizing: border-box;
  font: 300 1rem var(--selectedFontFamily);
}
