@mixin desktopInput {
  background-color: var(--mainNavColorSeeTrough);
  border-radius: 20px;
  padding: 15px 25px;
  border-bottom: white;
  box-shadow: unset;
  box-shadow: inset -6px -4px 5px white, inset 3px 2px 10px #ccd0d3;
}

@mixin mobileInput {
  outline: none;
  // color: grey;
  color: black;
  font: 300 1rem Titillium Web;
  width: 100%;
  padding: 6px;
  margin: 3px 0;
  border-radius: 2px;
  border: none;
  background-color: #ffffff;
  box-shadow: 0 2px 2px darkgrey;
  box-sizing: border-box;
  &:disabled {
    opacity: 1;
    background-color: #ffffff;
  }
}
