@use "../../../index.module.scss" as *;

.garage-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: Russo One;
  .tile {
    display: grid;
    place-content: center;
    cursor: pointer;
    background-color: #b42a3d;
    border: solid 1px #442829;
    box-shadow: inset -2px 0px 15px 0px rgba(78, 41, 45, 0.2),
      inset 2px 0px 15px 0px #4e292d, inset 0px 2px 15px 0px #8c202f,
      inset 0px -2px 15px 0px #4e292d;
    svg {
      width: 80px;
      height: 80px;
      justify-self: center;
      z-index: 1;
    }
    span {
      color: white;
      z-index: 1;
      text-align: center;
    }
    &:hover {
      background-color: var(--primaryHoverColor);
      svg {
        opacity: 1;
        path {
          fill: var(--primary);
        }
      }
    }
    &:active {
      background-color: var(--primaryHoverColor);
      span {
        color: black;
        opacity: 1;
        z-index: 1;
      }
      svg {
        opacity: 1;
        path {
          fill: #50c878;
        }
      }
    }
  }
  @media #{$laptop} {
    place-self: center;
    width: 60vw;
    height: 60%;
    max-width: 600px;
  }
}
