@use "../../styles//inputStyles.scss" as *;
@use "../../index.module.scss" as *;

.account-item-container {
  display: none;
  width: 100%;
  &.show {
    display: grid;
  }
  justify-items: center;
  label {
    font-family: var(--mainFontFamily);
  }
  input {
    @include mobileInput;
    max-width: 400px;
  }
  @media #{$laptop} {
    input {
      @include desktopInput;
    }
  }
}
