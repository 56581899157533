@use "../../styles/labelStyles.scss" as *;

.error-modal-container {
  display: none;
  &.show {
    display: grid;
  }
  position: absolute;
  align-content: center;
  justify-items: center;
  z-index: 200;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  pointer-events: none;
  label.error-modal-message-header {
    text-align: center;
    margin-top: 10px;
    @include label;
  }
  p.error-modal-message {
    padding: 10px;
    margin: 0;
    text-align: center;
  }
}
.swal2-icon {
  justify-content: center;
  width: 5em;
  height: 5em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  line-height: 5em;
  cursor: default;
  box-sizing: content-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  zoom: normal;
}
.swal2-icon-text {
  font-size: 3.75em;
}
.swal2-icon.swal2-error {
  border-color: #f27474;
}
.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #f27474;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
  left: 1.0625em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
  right: 1em;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.swal2-animate-error-icon {
  -webkit-animation: swal2-animate-error-icon 0.6s;
  animation: swal2-animate-error-icon 0.6s;
}
.swal2-animate-error-icon .swal2-x-mark {
  -webkit-animation: swal2-animate-error-x-mark 0.5s;
  animation: swal2-animate-error-x-mark 0.5s;
  animation-delay: 0.5s;
}

@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
