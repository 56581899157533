@use "./index.module.scss" as *;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main_container {
  width: 100vw;
  height: 100%;

  .backgroundMobile {
    background: url(/assets/AUDI_FRONT_PION.svg);
    height: 100%;
    width: 100vw;
    position: fixed;
    display: block;
    background-size: cover;
    top: 0px;
    left: 0px;
    z-index: -10;
  }
  .backgroundLaptop {
    background: url(/assets/AUDI_FRONT.svg);
    height: 100%;
    position: fixed;
    visibility: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    top: 60px;
    left: 0px;
    z-index: -10;
  }
  .homeBackground {
    height: 100%;
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: -10;
  }

  .main_section {
    display: grid;
    height: calc(100% - 60px);
  }
  .main_section:has(.shop-service-range-wrapper) {
    overflow: auto;
  }
  @media #{$laptop} {
    .main_section {
      // height: calc(100vh - 120px);
      height: calc(100vh - 60px);
    }
    .homeBackground {
      top: 0;
      .backgroundMobile {
        display: none;
      }
      .backgroundLaptop {
        visibility: visible;
      }
    }
  }
}

.footer {
  height: 5rem;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-items: center;
  text-transform: none;
  .footer_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding-left: 1rem;
    }
  }
  a {
    color: lightgrey;
    text-transform: none;
    font-size: 1rem;
    transition: all 100ms ease-in-out;
    &:hover {
      color: var(--primary);
    }
  }
}

.footer_container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  @media #{$mobileS} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  @media #{$laptop} {
    flex-direction: row;
  }
}
