@use "../../../../../styles/labelStyles.scss" as *;
@use "../../../../../index.module.scss" as *;

.review-wrapper {
  display: grid;
  justify-self: center;
  background-color: rgba(50, 65, 84, 0.7);
  width: 100%;
  overflow-y: auto;
  min-height: auto;
  max-height: calc(100% - 100px);
  position: absolute;
  grid-auto-rows: min-content;
  @media #{$laptop} {
    place-self: center;
    min-height: auto;
    max-height: 90;
  }

  .review-car-name {
    @include label;
    width: 100%;
    color: rgb(231, 217, 174);
  }
  div {
    display: grid;
    justify-items: center;
    padding: 10px;
  }
  .content {
    background-color: var(--mainNavColor);
    padding: 20px 10px 0px 10px;
    display: grid;
    justify-items: center;
    width: 90%;
    max-width: 400px;
    border-radius: 5px;
    row-gap: 10px;
    position: relative;
    span:nth-of-type(1) {
      text-align: center;
      word-break: break-word;
    }
    .author-name,
    .timestamp {
      display: grid;
      justify-self: end;
      padding: 2px 5px;
      font: 300 0.8rem var(--mainFontFamily);
    }
    .timestamp {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
.review-wrapper-no-reviews {
  display: grid;
  place-self: center;
  span {
    color: white;
    font: 300 1rem Russo One;
  }
}
