@use "../../../../styles/inputStyles.scss" as *;
@use "../../../../styles/labelStyles.scss" as *;
@use "../../../../index.module.scss" as *;

.find-shop-container {
  display: grid;
  align-content: center;
  justify-items: center;
  padding: 10px;
  div.searchBoxWrapper {
    display: grid;
    position: relative;
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    input {
      @include mobileInput;
      width: 100%;
    }
    div.search-button {
      position: absolute;
      display: grid;
      place-content: center;
      right: -2px;
      top: 3px;
      width: 45px;
      height: 39px;
      border-radius: 4px 2px 2px 4px;
      background-color: var(--primary);
      cursor: pointer;
      svg {
        height: 25px;
        path {
          fill: white;
        }
      }
      &:active {
        background-color: var(--primaryHoverColor);
      }
    }
  }
  .searchResultsWrapper {
    max-height: 60vh;
    overflow-y: auto;
    width: 100%;
    max-width: 600px;
    .no-results {
      display: none;
      &.show {
        display: grid;
      }
      place-content: center;
      font: 300 1rem Titillium Web;
      color: var(--primaryHoverColor);
      padding: 10px;
    }
    .searchItem {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 2fr 1fr;
      padding: 10px;
      background-color: white;
      margin-bottom: 5px;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      &.picked {
        background-color: rgba(var(--primaryRGB), 0.8);
      }
      .rating-box {
        display: grid;
        justify-self: end;
        align-self: start;
        position: relative;
        top: -3px;
        grid-column: 2;
        grid-row: 1/3;
      }
      span.find-shop-service-name {
        @include label;
        width: unset;
        text-align: unset;
      }
      .shop-address,
      .reviews {
        font: 300 0.8rem Titillium Web;
      }
      .reviews {
        grid-row: 2;
        grid-column: 2;
        place-self: end;
      }
    }
  }
  @media #{$laptop} {
    div.searchBoxWrapper {
      input {
        @include desktopInput;
      }
      div.search-button {
        height: 54px;
        border-radius: 4px 30% 30% 4px;
        width: 60px;
        svg {
          height: 30px;
        }
      }
    }
    .searchResultsWrapper .searchItem {
      background-color: var(--mainNavColorSeeTrough);
      &:hover {
        background-color: rgba(var(--primaryRGB), 0.8);
      }
      span.shop-address,
      .reviews {
        font: 300 1rem Titillium Web;
      }
    }
  }
}
