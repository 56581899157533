@use "../../../../styles/labelStyles.scss" as *;
@use "../../../../index.module.scss" as *;
@use "../../../../styles/buttonStyles.scss" as *;

.service-history-container {
  .service-history-item-container {
    display: grid;
    grid-template-columns: 80px 1fr;
    max-width: 600px;
    width: 100%;
    padding: 0 10px;
    position: relative;
    .service-history-item-timestamp {
      display: grid;
      grid-template-rows: repeat(3, min-content);
      background-color: white;
      grid-row: 1;
      border-radius: 4px 0 0 4px;
      .service-timestamp-month {
        font: 300 1rem Russo One;
        background-color: orange;
        color: white;
        text-align: center;
        border-radius: 4px 0 0 0px;
      }
      .service-timestamp-date {
        font: 300 2.3rem Russo One;
        text-align: center;
      }
      .service-timestamp-year {
        font: 300 1rem Russo One;
        text-align: center;
        margin-bottom: 15px;
      }
    }
    .service-history-item {
      background-color: rgba(var(--primaryRGB), 0.8);
      padding: 10px;
      width: 100%;
      max-width: 600px;
      display: grid;
      align-items: center;
      box-sizing: border-box;
      border-radius: 0px 4px 0px 0px;
      grid-column: 2;
      .service-item-car-wrapper {
        cursor: pointer;
      }
      .service-item-car-wrapper,
      .service-item-user-wrapper {
        grid-row: 2;
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: start;
        margin-bottom: 0.6rem;
        svg {
          height: 20px;
          width: 30px;
          grid-column: 1;
          path {
            fill: orange;
          }
        }
        span {
          align-self: center;
          @include label;
          font-size: 0.8rem;
          width: auto;
          color: white;
          white-space: normal;
        }
      }
      .service-item-user-wrapper {
        grid-row: 1;
      }
      .service-item-car-wrapper {
        background-color: var(--primaryHoverColor);
        border-radius: 5px;
      }
    }
    .service-history-item-status-bar {
      display: grid;
      grid-auto-flow: column;
      padding: 3px 0px;
      align-items: center;
      background-color: rgba(50, 65, 84, 0.7);
      grid-column: 1/3;
      border-radius: 0 0 4px 0;
      &.with-hover {
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
      }
      div {
        border-right: 1px solid darkgrey;
        justify-items: center;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: minmax(0, 1fr) 40px;
        align-items: center;
        &:last-of-type {
          border: none;
        }
        &:first-of-type {
          grid-template-columns: 1fr;
          span {
            padding: 0 5px;
          }
        }
        svg {
          height: 30px;
          width: 30px;
        }
        @media #{$laptop} {
          cursor: pointer;
          span {
            display: block;
          }
        }
        &:hover {
          span {
            font-weight: bold;
            color: mediumaquamarine;
          }
        }
      }
      .status-service-photos,
      .status-service-desc {
        font: 300 1rem var(--mainFontFamily);
        color: gainsboro;
        width: 48px;
        text-align: center;
      }
      .status-service-desc {
        width: 29px;
      }
      .status-desc,
      .status-desc-user {
        @include label;
        width: min-content;
        color: orange;
        text-transform: uppercase;
        justify-self: start;
        padding: 0 10px;
      }
      .status-bar-save-action-wraper {
        span {
          @include label;
          font-size: 0.9rem;
          width: 100%;
          display: block;
        }
      }
    }
    .edit-mode-action-button,
    .accept-service-action-button {
      position: absolute;
      right: 10px;
      bottom: 0;
      top: 0;
      margin: auto;
      display: grid;
      border-radius: 4px;
      background-color: var(--primaryHoverColor);
      &.service-closed {
        grid-template-columns: 40px;
        span {
          font: 300 1.4rem Russo One;
          color: orange;
          padding: 0px 2px;
        }
        svg {
          path {
            fill: #66cdaa;
          }
        }
      }
      div.double-action {
        display: grid;
        grid-template-rows: 1fr 1fr;
        div {
          display: grid;
          &:hover {
            background-color: salmon;
          }
        }
      }
      svg {
        height: 35px;
        width: 35px;
        position: relative;
        padding: 2px;
        z-index: 3;
        align-self: center;
        @media #{$laptop} {
          cursor: pointer;
        }
        path {
          fill: #6c7684;
        }
      }
      &.confirmed {
        background-color: mediumaquamarine;
        pointer-events: none;
        svg {
          path {
            fill: white;
          }
        }
      }
    }
    .item-container-action-buttons-slider {
      position: absolute;
      bottom: -22px;
      width: 100%;
      display: none;
      place-content: center;
      grid-auto-flow: column;
      animation: slideAnimation 0.5s;
      z-index: 2;
      &.show {
        display: grid;
      }
      button {
        @include button;
        font-size: 0.9rem;
        margin: 0 5px;
        padding: 10px;
        width: unset;
        box-shadow: 0 2px 8px #222;
        &:nth-last-of-type(1) {
          background-color: #7cb342;
          &:hover {
            color: var(--primaryHoverColor);
          }
        }
      }
    }
  }
  .service-history-item-timestamp {
    span.service-timestamp-month.admin-accounts,
    span.service-timestamp-year.admin-accounts {
      font: 300 0.9rem Russo One;
      display: grid;
      text-align: center;
      align-self: center;
    }
    span.service-timestamp-year.admin-accounts {
      color: grey;
      font-size: 1.2rem;
    }
  }
  .service-history-list-container {
    .user-details-container {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: orange;
      display: none;
      border-radius: 4px;
      align-items: center;
      &.show {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 40px minmax(0, 1fr) minmax(0, 1fr);
      }
      .icon-wrapper {
        grid-column: 1;
        background-color: var(--primaryHoverColor);
        display: grid;
        align-self: stretch;
        svg {
          place-self: center;
          height: 20px;
          width: 25px;
          path {
            fill: var(--primary);
          }
        }
        &:nth-of-type(10) {
          svg {
            path:first-child {
              fill: none;
            }
          }
        }
      }
      div.details-span-wrapper {
        grid-column: 2/4;
        span {
          font: 300 0.8rem Russo One;
          color: var(--primaryHoverColor);
          margin: 0 5px;
          background-color: rgba(255, 255, 255, 0.2);
          padding: 2px;
        }
      }
      .details-income-wrapper {
        position: relative;
      }
      .details-income {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        visibility: hidden;
        background-color: orange;
        z-index: 999999;
        tr {
          td {
            border-bottom: 2px solid black;
          }
        }
        &.show {
          display: block;
          visibility: visible;
        }
      }

      .close-user-details {
        position: absolute;
        right: 5px;
        top: -10px;
        font-size: 35px;
        color: rgb(191, 57, 84);
        z-index: 2;
        cursor: default;
      }
    }
    .service-history-item {
      .user-details-button {
        position: absolute;
        right: 15px;
        top: 0px;
        width: 30px;
        height: 30px;
        z-index: 1;
        path {
          fill: orange;
        }
      }
    }
    .service-history-item-status-bar.admin {
      grid-column: 1/3;
      grid-template-columns: minmax(0, 1fr) 37px;
      div span:first-child {
        overflow-x: hidden;
        width: 100%;
        text-align: left;
      }
      .remove-account {
        display: grid;
        grid-template-columns: 1fr;
        width: 37px;
        place-items: center;
        padding: 0 !important;
      }
      .status-desc-user.affiliated {
        color: mediumaquamarine;
      }
    }
  }
}
