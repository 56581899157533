@use "../../../../index.module.scss" as *;
@use "../../../../styles/inputStyles.scss" as *;

.shop-title {
  padding: 5px 0;
  color: orange;
  font: 300 0.9rem Russo One;
  text-align: center;
  background-color: var(--primaryHoverColor);
}
.shop-layout-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 165px 70px 1fr;
  .shop-main-photo-wrapper {
    display: grid;
    grid-column: 1/6;
    background-color: #ddd;
    grid-template-rows: auto 50px;
    position: relative;
    input.service-photo-input,
    .photo-input-placeholer-container {
      position: absolute;
      width: 100%;
      top: 0;
    }

    .photo-input-placeholer-container {
      padding: 5px;
      display: grid;
      position: relative;
      height: calc(100%);
      .dashed-box {
        border: 2px dashed grey;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        display: grid;
        justify-items: center;
        align-content: center;
        &.hidden {
          visibility: hidden;
        }
        svg {
          height: 40px;
          width: 40px;
        }
      }
      img.shop-card-photo {
        position: absolute;
        max-height: 100%;
        height: auto;
        max-width: 100%;
        width: auto;
        z-index: 10;
        object-fit: cover;
        left: 0;
        top: 0;
        right: 0;
        margin: auto;
        &.hidden {
          visibility: hidden;
        }
      }
    }
    input.service-photo-input {
      z-index: 12;
      opacity: 0;
      height: calc(100% - 50px);
    }
    .shop-links-bar {
      grid-row: 2;
      display: grid;
      grid-auto-flow: column;
      //hidden for now
      visibility: hidden;
      div {
        width: 100%;
        display: grid;
        place-items: center;
        &:hover,
        &.active {
          background-color: lightgrey;
          cursor: pointer;
          svg {
            fill: white;
          }
        }
      }
    }
  }
  .shop-reviews-wrapper {
    display: grid;
    grid-column: 6/9;
    background-color: var(--mainNavColorSeeTrough);
    .star-count {
      display: grid;
      place-items: center;
    }
    .phoneLink {
      color: white;
    }
    span {
      text-align: center;
      cursor: pointer;
      &.promotions {
        color: violet;
      }
    }
    .read-reviews {
      cursor: pointer;
      &:hover {
        color: violet;
      }
    }
    .reviews-bottom-bar {
      height: 50px;
      width: 100%;
      background-color: var(--primary);
      align-self: end;
      color: white;
      display: grid;
      place-items: center;
      cursor: pointer;
      &:hover {
        background-color: mediumaquamarine;
      }
      span {
        font: 300 1rem Russo One;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
  .shop-contact,
  .shop-hours {
    height: 70px;
    padding: 5px;
    display: grid;
    background-color: rgba(50, 65, 84, 0.7);
    grid-column: 1/5;
    place-content: center;
    .phoneLink {
      color: white;
    }
    span {
      align-self: center;
      font: 300 1rem Russo One;
      color: white;
      text-align: center;
      margin-right: 4px;
    }
    svg {
      place-self: center;
      path {
        fill: orange;
      }
    }
    &.social-link {
      visibility: hidden;
    }
  }
  .shop-hours {
    grid-column: 5/9;
    svg {
      width: 35px;
      height: 35px;
      path {
        fill: orange;
      }
    }
  }
  .social-link-input-wrapper {
    height: 70px;
    width: 100%;
    background-color: lightgrey;
    display: none;
    grid-column: 1/9;
    grid-row: 2;
    place-content: center;
    input {
      @include mobileInput;
      width: 80vw;
      max-width: 550px;
      margin-left: 10px;
      text-transform: lowercase;
    }
    &.social-link {
      display: grid;
      grid-auto-flow: column;
      svg {
        align-self: center;
      }
    }
  }
  .shop-service-range-wrapper {
    grid-column: 1/9;
    grid-row: 3;
    margin: 5px 10px;
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    column-gap: 5px;
    span {
      text-align: center;
    }
    div {
      width: 100%;
      position: relative;
      svg {
        width: 10px;
        height: 10px;
        left: unset;
        right: 5px;
      }
      input {
        @include mobileInput;
        background-color: white;
        @media #{$laptop} {
          @include desktopInput;
        }
        &:disabled {
          color: black;
        }
      }
    }
  }
  @media #{$laptop} {
    padding-left: 130px;
    grid-template-rows: 260px 70px 1fr;
    .shop-contact,
    .shop-hours {
      grid-auto-flow: column;
    }
  }
}
