@import url("https://fonts.googleapis.com/css2?family=Russo+One&family=Titillium+Web:wght@300;600&display=swap");

:root {
  /* background */
  --primary: rgb(143, 53, 68);
  --primary-mobile: white;
  --mainNavColor: rgb(245, 245, 244);
  --mainNavColorSeeTrough: #f5f5f4;
  --mobileMenuColor: #2f3545;
  /* hover */
  --primaryHoverColor: rgb(41, 52, 64);
  --hoverFontColor: salmon;
  /* fonts */
  --mainFontFamily: Titillium Web;
  --selectedFontFamily: Russo One;
  --mainFontColor: white;
  --lightFontColor: lightgrey;
  /* use following with rgba */
  --darkFontColor: 35, 43, 43;
  --primaryRGB: 143, 53, 68;
}

$mobileS: "screen and (min-width: 320px)";
$mobileM: "screen and (min-width: 375px)";
$mobileL: "screen and (min-width: 525px)";
$tablet: "screen and (min-width: 768px)";
$laptop: "screen and (min-width: 1024px)";
$laptopL: "screen and (min-width: 1440px)";
$desktop: "screen and (min-width: 2560px)";
