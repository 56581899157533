@use "../../styles/buttonStyles.scss" as *;

.consent-form-wrapper {
  display: none;
  flex-direction: column;
  position: absolute;
  width: 100vw;
  max-width: 500px;
  height: 37vh;
  padding: 10px;
  overflow-y: auto;
  background-color: var(--mainNavColor);
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 6px 6px 15px -2px grey;
  box-sizing: border-box;
  &.show {
    display: flex;
  }
  p {
    color: black;
    font: 300 1rem Titillium Web;
    text-align: center;
  }
  .consent-form-actions-wrapper-body {
    padding-top: 2rem;
  }
  .consent-form-label {
    padding-top: 0.8rem;
  }
  .consent-form {
    display: flex;
  }

  .consent-form-actions-wrapper-footer {
    display: flex;
    gap: 10px;
    height: 45px;
    height: 45px;
    margin-top: auto;
    button {
      @include button;
      width: auto;
      flex: 1;
      &:nth-of-type(2) {
        background-color: mediumaquamarine;
        &:hover {
          color: var(--primaryHoverColor);
        }
      }
    }
  }
}
