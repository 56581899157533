@use "../../index.module.scss" as *;

.bottomMenu {
  display: grid;
  grid-auto-flow: column;
  position: absolute;
  align-self: end;
  background-color: #f1f3f1;
  height: 60px;
  width: 100vw;
  div {
    position: relative;
    display: grid;
  }
  div.active::before {
    position: absolute;
    width: 60px;
    height: 40px;
    background-color: #2f3545;
    content: "";
    border-radius: 60px 60px 0 0;
    top: -6px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  svg {
    display: grid;
    z-index: 5;
    place-self: center;
    width: 40px;
    height: 40px;
  }
}

@media #{$laptop} {
  .bottomMenu {
    display: none;
  }
}
