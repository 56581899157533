@use "../../index.module.scss" as *;

.link-wrapper {
  display: grid;
  justify-content: center;
  color: white;
  overflow-y: auto;
  img.tutorial {
    width: 320px;
    position: unset;
    @media #{$laptop} {
      width: 800px;
    }
  }
}
