@use "../../index.module.scss" as *;
.account-sidebar {
  display: none;
  z-index: 2;
  font: 300 1rem var(--selectedFontFamily);
  position: relative;
  .account-header {
    display: grid;
    align-self: start;
    background-color: var(--mainNavColor);
    border-bottom: 6px solid var(--primary);
    label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100vw;
      text-align: center;
      padding: 5px;
      position: relative;
      box-sizing: border-box;
      span {
        font-family: var(--mainFontFamily);
      }
    }
  }
  svg {
    fill: #fff;
  }
}

@media #{$laptop} {
  .account-sidebar {
    display: grid;
    grid-row: 2;
    position: absolute;
    left: 0;
    top: 2px;
    height: 100%;
    grid-auto-flow: column;
    grid-template-columns: 30px 70px 30px;
    z-index: 6;
    .sideMenu-arrows {
      align-self: center;
      grid-row: 1;
      z-index: 2;
      height: 30px;
      cursor: pointer;
      path {
        // fill: orange;
      }
      &:nth-of-type(1) {
        &.menu {
          display: none;
        }
        grid-column: 1;
      }
      &:nth-of-type(2) {
        display: none;
        grid-column: 3;
        &.items {
          display: block;
        }
      }
    }
  }
  .account-sidebar-menu {
    display: grid;
    width: 30px;
    &.menu {
      width: 130px;
    }
    height: 100%;
    grid-column: 1/4;
    grid-row: 1;
    background-color: var(--primary);
    transition: width 0.2s ease-in;
    box-shadow: 2px 0px 2px black;
    svg {
      width: 40px;
      height: 40px;
    }
    .dropdown-link {
      display: none;
      &.items {
        display: grid;
      }
      justify-items: center;
      align-content: center;
      position: relative;
      width: 130px;
      span {
        display: grid;
        place-self: center;
        font-size: 1rem;
        z-index: 100;
        margin-top: 15px;
      }
      &:hover {
        background-color: var(--primaryHoverColor);
        cursor: pointer;
        span {
          color: white;
        }
        svg path {
          // fill: orange;
        }
      }
    }
  }
}
