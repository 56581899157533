@use "../../../../index.module.scss" as *;
@use "../../../../styles/inputStyles.scss" as *;

.add-car-container {
  display: grid;
  justify-self: center;
  grid-template-rows: repeat(7, min-content) 1fr;
  padding: 10px;
  width: 95%;
  box-sizing: border-box;
  max-width: 400px;
  .select {
    width: 100%;
    @include mobileInput;
  }
  button {
    background-color: var(--primary);
    border-radius: 20px;
    border: none;
    box-shadow: 2px 2px 5px black;
    cursor: pointer;
    width: 50vw;
    max-width: 200px;
    justify-self: center;
    font: 300 1.5rem Russo One;
    color: white;
    padding: 15px;
    align-self: center;
    &:hover {
      background-color: var(--primaryHoverColor);
      color: orange;
    }
  }
  @media #{$laptop} {
    .select {
      @include desktopInput;
      padding: 0;
      .wrapper li,
      &.registration {
        padding: 15px 25px;
      }
    }
  }
}
