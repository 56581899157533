@use "../../../styles//inputStyles.scss" as *;
@use "../../../index.module.scss" as *;

.user-container {
  display: grid;
  justify-self: center;
  grid-template-rows: repeat(9, min-content);
  grid-template-columns: 1fr 80px;
  padding: 10px;
  width: 95%;
  max-width: 600px;
  box-sizing: border-box;
  grid-column-gap: 5px;
  span {
    font: 300 1.2rem "Titillium Web";
    text-transform: uppercase;
    color: black;
  }
  .userField {
    display: grid;
    align-self: start;
    .select-wrapper {
      top: 45px;
    }
    input,
    .select .default-option li {
      @include mobileInput;
      &.text-center {
        text-align: center;
      }
    }
    input {
      &[type="number"] {
        --moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    .select .select-option li {
      background-color: rgba(255, 255, 255);
      color: var(--primaryHoverColor);
      font: 300 1rem Titillium Web;
      &:hover {
        background-color: pink;
      }
    }
  }
  .userField.col-2 {
    grid-column: 1/3;
  }
  .userField.col-2.hidden {
    display: none;
  }
  svg {
    width: 60px;
    height: 60px;
    align-self: end;
    justify-self: center;
    cursor: pointer;
    path,
    circle {
      fill: var(--primary);
    }
    &:hover {
      path {
        fill: orange;
      }
      circle {
        fill: salmon;
      }
    }
  }
  button {
    background-color: var(--primary);
    border-radius: 20px;
    border: none;
    box-shadow: 2px 2px 5px black;
    grid-column: 1/3;
    cursor: pointer;
    width: 50vw;
    max-width: 200px;
    justify-self: center;
    font: 300 1.5rem Russo One;
    color: white;
    padding: 15px;
    align-self: center;
    &:hover {
      background-color: var(--primaryHoverColor);
      color: orange;
    }
  }
  @media #{$laptop} {
    color: #e4ad52;
    grid-template-columns: 1fr 150px;
    button {
      margin-bottom: 30px;
    }
    .userField {
      input {
        @include desktopInput;
      }
    }
  }
  span {
    margin-left: 10px;
  }
}
