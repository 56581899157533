@use "../../styles//buttonStyles.scss" as *;
@use "../../styles//labelStyles.scss" as *;
@use "../../index.module.scss" as *;

.response-container {
  display: grid;
  align-content: center;
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  .response-header {
    height: 40px;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .response-main-section {
    background-color: var(--mainNavColor);
    height: 220px;
  }
  .response-back-home-button {
    @include button;
    justify-self: center;
    border-radius: 0 0 4px 4px;
    max-width: 400px;
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
}
