@use "../../styles//buttonStyles.scss" as *;

$toggler-size: 40px;
$item-size-desktop: 60px;
$toggler-color: orange;
$toggler-transition: transform 0.5s, top 0.5s;

$item-count: 4;
$item-size: $toggler-size * 2;

$item-color: white;
$item-transition: 0.5s;

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin absolute-center {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

@mixin line {
  @include size($toggler-size, calc($toggler-size/8));
  display: block;
  z-index: 1;
  border-radius: calc($toggler-size/16);
  background: rgba($toggler-color, 0.7);
  transition: $toggler-transition;
}

// styles
.menu-item {
  $menuItem: &;
  @include absolute-center;
  @include size($item-size);
  display: block;
  opacity: 0;
  cursor: pointer;
  transition: $item-transition;
}

div.radial-menu {
  position: absolute;
  display: grid;
  place-items: center;
  height: 100%;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 201;
  overflow-x: hidden;
  .phoneLink {
    color: white;
  }
  div.info {
    width: 0px;
    opacity: 0;
    span {
      transform: scale(0.01);
    }
    &.phone,
    &.email {
      display: grid;
      place-content: center;
      width: 90vw;
      height: 100px;
      max-width: 450px;
      border-radius: 4px;
      background-color: var(--primaryHoverColor);
      position: relative;
      top: 42%;
      transition: width 0.2s ease-out, opacity 0.15s;
      transition-delay: 0.3s;
      color: orange;
      opacity: 1;
      span {
        font: 300 1rem var(--selectedFontFamily);
        word-break: break-all;
        cursor: default;
        visibility: visible;
        transition: transform 0.1s;
        transform: scale(1);
        transition-delay: 0.5s;
      }
      button {
        @include button;
        position: absolute;
        bottom: -30px;
        width: 60%;
        left: 0;
        right: 0;
        margin: auto;
        &:hover {
          background-color: orange;
          color: var(--primaryHoverColor);
        }
      }
    }
  }
  label {
    &:before,
    &:after {
      background-color: var(--primaryHoverColor);
    }
  }
  &.hidden {
    visibility: hidden;
  }
}
.menu-toggler {
  @include absolute-center;
  @include size($toggler-size);
  z-index: 2;
  &:hover + label:before,
  &:hover + label:after {
    background: $toggler-color;
  }
  &.collapse {
    visibility: hidden;
  }
}
.menu-toggler ~ ul.swirl {
  .menu-item {
    opacity: 1;
  }

  @for $i from 0 through $item-count - 1 {
    .menu-item:nth-child(#{$i + 1}) {
      transform: rotate(calc(360deg / $item-count * $i))
        translateX(-$item-size - 20px);
      &.collapse {
        transform: translateX(0px);
        visibility: hidden;
        opacity: 0;
      }
    }
    @media screen and (min-width: 1024px) {
      .menu-item:nth-child(#{$i + 1}) {
        transform: rotate(calc(360deg / $item-count * $i))
          translateX(-$item-size-desktop - 100px);
      }
    }
  }
  .menu-item div {
    pointer-events: auto;
    display: grid;
    place-content: center;
    background-color: var(--primaryHoverColor);
    border-radius: 50%;
    width: inherit;
    height: inherit;
    font-size: calc($item-size/2);
    transition: 0.2s;
    cursor: default;
    .radial-menu-item-desc {
      font-size: 0.7rem;
      text-align: center;
      color: white;
      opacity: 0.9;
    }
    svg {
      path {
        fill: orange;
      }
    }
    &:hover {
      box-shadow: 0 0 0 calc($item-size/40) rgba(white, 0.3);
      background-color: salmon;
      font-size: calc($item-size/1.8);
      svg {
        path {
          fill: $item-color;
          transform: scale(1.2);
          transform-origin: center;
          transition: transform 0.4s;
        }
      }
    }
  }
}

.menu-toggler + label {
  @include line;
  @include absolute-center;
  background: transparent;
  &.collapse {
    visibility: hidden;
  }

  &:before,
  &:after {
    @include line;
    content: "";
    position: absolute;
    width: $toggler-size;
    transform-origin: center;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

@for $i from 0 through $item-count {
  .menu-item:nth-child(#{$i + 1}) div {
    transform: rotate(calc(360deg - 360deg / $item-count * $i));
  }
}

@media (min-width: 1024px) {
  .menu-toggler {
    @include size($item-size-desktop);
  }
  .menu-item {
    @include size($item-size + 40px);
    div {
      cursor: pointer;
    }
  }
}
