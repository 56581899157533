.legal-terms-wrapper {
  overflow-y: scroll;
  height: calc(100vh - 150px);
  display: grid;
  justify-content: center;
  p {
    color: white;
    font: 300 1rem Titillium Web;
    width: 100vw;
    max-width: 500px;
    text-align: center;
    color: black;
    background-color: var(--mainNavColor);
    padding: 10px;
    border-radius: 5px;
  }
}
