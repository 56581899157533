@use "../../index.module.scss" as *;
@use "../../styles/inputStyles.scss" as *;

.select-container {
  display: grid;
  place-self: center;
  position: relative;
  width: 100%;
  ul {
    list-style: none;
    padding: 0;
    margin: 0px;
  }
  .wrapper {
    cursor: pointer;
    position: relative;
    div.dummy-wrapper {
      position: absolute;
      border-radius: 20px;
      height: 100%;
      width: 100%;
      visibility: visible;
      z-index: 2;
      &.expand {
        visibility: hidden;
      }
    }
    &::after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      border: 2px solid;
      width: 10px;
      height: 10px;
      top: 15px;
      right: 10px;
      border-color: transparent transparent grey grey;
      transform: rotate(-45deg);
    }
    .default-option li {
      @include mobileInput;
      &.text-center {
        text-align: center;
      }
      &.disabled {
        color: rgba(34, 34, 43, 0.5);
        // color: rgba(34, 34, 43, 0.2);
      }
    }
  }
  .select-wrapper {
    position: absolute;
    width: 100%;
    height: 0;
    transition: max-height 0.3s ease;
    overflow-y: scroll;
    border-radius: 5px;
    z-index: 3;
    &.expand {
      height: auto;
      max-height: 300px;
    }
    :focus {
      outline: none;
    }
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 0 5px 5px 0;
    }
    &::-webkit-scrollbar-track {
      background: lightgrey;
      border-radius: 0 5px 5px 0;
      background: lightgrey;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--primary);
      border-radius: 5px;
      &:hover {
        background: orange;
      }
    }
    .select-option {
      li {
        padding: 10px 10px;
        cursor: pointer;
        background-color: rgba(255, 255, 255);
        color: var(--primaryHoverColor);
        font: 300 1rem Titillium Web;
        &:hover {
          background-color: pink;
        }
      }
    }
  }
  @media #{ $laptop } {
    .wrapper::after {
      width: 12px;
      height: 12px;
      top: 20px;
      right: 20px;
    }
    .select-wrapper {
      top: 60px;
    }
    .wrapper .default-option li {
      @include desktopInput;
    }
    .select-wrapper .select-option li {
      background-color: rgba(235, 235, 235);
    }
  }
}
