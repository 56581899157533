@use "../../index.module.scss" as *;
@use "../../styles/buttonStyles.scss" as *;

.login-wrapper {
  display: grid;
  place-items: center;
  grid-area: light;
  grid-column: span 2;
  height: calc(100vh - 5em);
  padding-top: 5em;
  min-height: 800px;
  overflow: auto;
}
.login-container {
  max-width: 400px;
  width: 90%;
  height: 400px;
  display: grid;
  grid-template-rows: 1fr max-content max-content max-content max-content 1fr;
  align-self: center;
  justify-self: center;
  z-index: 2;
  // background-color: rgba(18, 19, 23, 0.8);
  padding: 10px;
  margin: 30px 0;
  border-radius: 5px;
  box-sizing: border-box;
  font: 300 2em var(--mainFontFamily);
  color: var(--mainFontColor);
  p {
    font-size: 0.8rem;
    margin: 0;
  }
  p.login-header {
    text-align: center;
    align-self: center;
    font: inherit;
    cursor: initial;
    // color: var(--mainFontColor);
  }
  img.mh-logo {
    width: 100px;
    height: 100px;
    position: unset;
    place-self: center;
    margin-bottom: 15px;
  }
  input {
    width: 100%;
    // background-color: rgba(41, 52, 64, 0.4);
    // outline: none;
    // border: none;
    border: 1px solid gray;
    font: inherit;
    font-size: 1rem;
    // color: inherit;
    border-radius: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
  }
  p.forgot-password-prompt {
    text-align: right;
    align-self: end;
    cursor: pointer;
    margin: 10px;
    // color: var(--lightFontColor);
    &:hover {
      // color: var(--mainFontColor);
      color: gray;
    }
  }
  p.form-validator-prompt {
    text-align: center;
    background-color: rgba(143, 53, 68, 0.6);
  }
  p.change-password-note {
    grid-row: 1;
    grid-column: 1/3;
    // justify-self: end;
    // color: var(--lightFontColor);
  }
  .change-pass-action-buttons {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 80px 1fr;
    grid-gap: 10px;
    width: 100%;
    max-width: 400px;
    align-content: end;
    grid-row: 5/7;
    .back-to-login-button {
      @include button;
      width: 80px;
      padding: 0;
      grid-row: 2;
      svg {
        width: 100%;
        font-size: 2.6rem;
        display: grid;
        justify-self: center;
        margin: 0;
      }
    }
    .change-password-button {
      @include button;
      color: white;
      max-width: 350px;
      padding: 0;
      grid-row: 2;
    }
  }
  .button-login {
    @include button;
  }
  @media #{$laptop} {
    min-height: 400px;
    p {
      font-size: 1rem;
    }
  }
}
