@use "../../../../styles/labelStyles.scss" as *;
@use "../../../../index.module.scss" as *;

.service-history-list-container {
  .service-history-list-container-note-modal {
    &.show {
      display: grid;
    }
    position: absolute;
    height: 300px;
    background-color: lightgrey;
    border-radius: 5px;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    grid-template-rows: 30px 1fr;
    justify-items: center;
    width: 100%;
    max-width: 600px;
    .note-modal-close {
      .close {
        padding: 10px;
        position: absolute;
        right: 0;
        top: -10px;
        width: 32px;
        height: 32px;
      }
      .close:before,
      .close:after {
        position: absolute;
        left: 15px;
        content: " ";
        height: 33px;
        width: 2px;
        background-color: orange;
      }
      .close:before {
        transform: rotate(45deg);
      }
      .close:after {
        transform: rotate(-45deg);
      }
    }
    .note-modal-header {
      height: 30px;
      background-color: #293440;
      width: 100%;
      display: grid;
      align-items: center;
      justify-items: end;
      grid-auto-flow: column;
      span {
        @include label;
        justify-self: center;
        padding: 0 10px;
        width: 100%;
        color: lightgrey;
        grid-column: 1/3;
        grid-row: 1;
      }
      svg {
        height: 20px;
        width: 20px;
        margin-right: 10px;
        grid-column: 2;
        grid-row: 1;
        z-index: 3;
        path {
          fill: white;
        }
      }
    }
    textarea {
      resize: none;
      background-color: var(--mainNavColor);
      border-radius: 4px;
      height: 250px;
      width: 90%;
      outline: none;
      border: none;
      padding: 10px 10px 60px 10px;
      font: 300 1rem Titillium Web;
    }
    .add-note-button {
      position: absolute;
      bottom: 10px;
      font: 300 2.5rem Titillium Web;
      width: 150px;
      border: none;
      border-radius: 5px;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
    }
  }
}
