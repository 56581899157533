@use "../../../../index.module.scss" as *;
@use "../../../../styles/labelStyles.scss" as *;
@use "../../../../styles/buttonStyles.scss" as *;

.service-history-container {
  .service-history-list-container {
    .rating-click-wrapper {
      position: absolute;
      width: 1px;
      height: 1px;
      bottom: 45px;
      left: 20px;
      z-index: 2;
      padding: 10px 0;
      .rating-box {
        position: absolute;
        transform: scale(0.9);
        cursor: pointer;
      }
      @media #{$laptop} {
        .rating-box {
          transform: scale(1);
        }
      }
    }
    .service-history-item {
      &.confirmed {
        background-color: mediumaquamarine;
      }
    }
    .service-item-user-wrapper {
      cursor: pointer;
      &.shop {
        svg {
          height: 28px;
          path {
            fill: var(--primaryHoverColor);
          }
        }
      }
    }
    .service-history-item-status-bar {
      &.user {
        grid-template-columns: 1fr minmax(40px, 0.5fr) minmax(40px, 0.65fr);
        div:not(:first-of-type) {
          padding: 0 10px;
        }
        div:first-of-type {
          pointer-events: none;
          .status-desc-user.archived {
            color: mediumaquamarine;
          }
        }
        .status-desc-user {
          display: block;
        }
      }
    }
    .item-container-action-buttons-slider {
      width: 100%;
      display: none;
      justify-content: space-between;
      z-index: 99999;
      border-radius: 0 0 5px 5px;

      &.show {
        display: flex;
      }
      button {
        @include button;
        font-size: 0.9rem;
        margin: 0 5px;
        padding: 10px;
        box-shadow: 0 2px 8px #222;
        &:nth-last-of-type(1) {
          background-color: #7cb342;
          &:hover {
            color: var(--primaryHoverColor);
          }
        }
      }
    }
    .service-history-no-results {
      span {
        @include label;
        color: var(--mainNavColor);
      }
    }
  }
}
