@use "../../index.module.scss" as *;
@use "../../styles/buttonStyles.scss" as *;

.navbar_container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 40px 1fr 45px;
  grid-template-rows: 60px;
  background-color: #f5f5f4;
  position: relative;
  z-index: 3;
  width: 100vw;
  .nav_hamburger {
    display: grid;
    align-self: center;
    height: 40px;
    width: 40px;
  }
  .go_back {
    display: grid;
    align-self: center;
    height: 30px;
    padding-left: 6px;
  }
  .nav_link_section,
  .nav_link_section_desktop {
    grid-auto-flow: column;
    align-items: center;
    svg {
      margin: 0px 20px;
      justify-self: end;
    }
    .nav_social_links {
      justify-self: center;
      display: flex;
      justify-content: space-between;
      width: 250px;
      height: 40px;
      .nav_social_link {
        position: unset;
        width: 40px;
        height: 40px;
        fill: lightgrey;
        cursor: pointer;
        opacity: 0;
        transition: opacity 0.3s ease;
        transition-delay: 0.2s;
      }
    }
  }
  .nav_link_section_desktop {
    display: none;
  }
  .nav_link_section_mobile {
    display: grid;
    opacity: 0;
    transition: opacity 0.15s;
    &.show {
      opacity: 1;
      .nav_social_link {
        opacity: 1;
      }
    }
  }
  .nav_app_title {
    display: grid;
    grid-auto-flow: column;
    place-content: center;
    cursor: pointer;
    span {
      display: grid;
      font: 1.6em Russo One;
    }
    span:nth-of-type(1) {
      color: var(--primary);
    }
  }
  @media #{$laptop} {
    grid-auto-flow: row;
    // grid-template-rows: 60px 60px;
    grid-template-rows: 60px;
    grid-template-columns: unset;
    .nav_hamburger {
      display: none;
    }
    .go_back {
      display: none;
    }
    .nav_link_section_desktop {
      display: grid;
      grid-row: 1;
      grid-column: 2;
      .nav_social_links .nav_social_link {
        opacity: 1;
      }
    }
    .nav_link_section_mobile {
      display: none;
    }
  }
}
