@mixin button {
  background-color: var(--primary);
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 5px;
  font: 400 1.2rem Russo One;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 0px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  &:hover {
    background-color: var(--primaryHoverColor);
    color: var(--hoverFontColor);
  }
}
