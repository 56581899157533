.my-cars-container {
  display: grid;
  place-self: center;
  width: 100%;
  max-width: 500px;
  height: calc(100vh - 180px);
  padding: 10px;
  box-sizing: border-box;
  grid-row: 1;
  overflow-y: auto;
  --webkit-overflow-scrolling: touch;
  .car-item {
    display: grid;
    background-color: var(--mainNavColorSeeTrough);
    padding: 5px;
    gap: 5px;
    margin-bottom: 10px;
    border-radius: 4px;

    .car-logo {
      display: grid;
      position: relative;
      place-items: center;
      background-color: #77dd77;
      span {
        position: absolute;
        left: 2px;
        top: 2px;
        border: none;
        font-size: 12px;
      }
      svg {
        width: 100px;
        margin-left: 15px;
      }
    }
    span,
    div {
      display: grid;
      align-items: center;
      border: 2px solid grey;
      border-radius: 5px;
      text-align: center;
    }
    .car-name {
      grid-row: 1/3;
      grid-column: 2/4;
    }
  }
  &.empty {
    align-content: center;
    span {
      text-align: center;
      color: white;
      font: 300 1rem Russo One;
    }
  }
}

.car-item-container {
  display: "flex";
  flex-direction: "column";
  margin-bottom: 20px;
  .car-item-wrapper {
    position: relative;
    .car-item-button {
      // position: absolute;
      // right: 0;
      // left: 0;
      // bottom: -1.8rem;
      padding: 0.5rem 0.2rem;
      border-radius: 5px;
      font-size: 1rem;
      // width: auto;
      grid-column: span 3;
    }
  }
}

.car-item-overlay {
  border-color: red;
  border: 0.5rem solid red;
}
.car-lock {
  overflow: hidden;
  z-index: 9999;
  position: absolute;
  width: 120px;
  display: block;
  transform: translateX(50%);
}
