@use "../../../../styles/labelStyles.scss" as *;

.car-info-modal {
  align-items: center;
  background-color: lightgrey;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 300px;
  left: 0;
  position: absolute;
  right: 0;
  width: 300px;
  z-index: 100;

  .car-info-modal-content {
    background-color: var(--mainNavColor);
    display: flex;
    flex-direction: column;
    width: 90%;
    border-radius: 4px;
    .car-info-modal-content-info {
      @include label;
      font-size: 0.8rem;
      width: auto;
      white-space: normal;
      text-align: left;
      a {
        color: var(--selectedFontFamily);
      }
    }
  }

  .car-info-modal-header {
    align-content: center;
    align-items: center;
    background-color: #293440;
    display: flex;
    height: 32px;
    padding: 0 5px;
    justify-content: space-between;
    width: 100%;

    .car-info-modal-header-title {
      flex-shrink: 0;
      color: lightgrey;
      font: 300 1rem var(--selectedFontFamily);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .close {
      padding: 10px;
      position: absolute;
      right: 0;
      top: -10px;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    .close:before,
    .close:after {
      content: "";
      position: absolute;
      left: 15px;
      height: 33px;
      width: 2px;
      background-color: orange;
    }
    .close:before {
      transform: rotate(45deg);
    }
    .close:after {
      transform: rotate(-45deg);
    }
  }
}
